module.exports = {
	langType : 'zh-CN',
	lg1:"可提取余额",
	lg2:"提取详情",
	lg3:"一键提取",
	lg4:"我的质押",
	lg5:"质押记录",
	lg6:"一键质押",
	lg7:"质押",
	lg8:"预计每日可挖出 ",
	lg9:"该数据仅供参考,请以实际挖矿数据为准 !",
	lg10:"质押总量",
	lg11:"下次挖矿总量",
	lg12:"预计下次挖到",
	lg13:"我的专属分享链接",
	lg14:"复制",
	lg15:"累计邀请收益",
	lg16:"收益详情",
	lg17:"地址",
	lg18:"注册时间",
	lg19:"加载中",
	lg20:"暂无更多数据~",
	lg21:"点击加载更多",
	lg22:"累计已提取",
	lg23:"剩余可提取",
	lg24:"金额",
	lg25:"类型",
	lg26:"时间",
	lg27:"产矿",
	lg28:"提取",
	lg29:"来自",
	lg30:"本次质押后预计每日可挖出约 ",
	lg31:"同时,需要您支付 ",
	lg32:"确认支付?",
	lg33:"取消",
	lg34:"确认",
	lg35:"一键注册",
	lg36:"请输入邀请人地址",
	lg37:"请输入正确的地址",
	lg38:"上级地址不能为自己的",
	lg39:"注册中",
	lg40:"注册成功",
	lg41:"注册失败:",
	lg42:"请输入正确的质押数量",
	lg43:"获取余额中",
	lg44:"余额不足",
	lg45:"质押授权中",
	lg46:"质押失败:",
	lg47:"质押中",
	lg48:"质押成功",
	lg49:"一键赎回",
	lg50:"你暂时没有可提取的收益",
	lg51:"领取收益中",
	lg52:"提取成功",
	lg53:"提取失败:",
	lg54:"复制成功",
	lg55:"复制失败",
	lg56:"质押时间",
	lg57:"质押数量",
	lg58:"质押状态",
	lg59:"赎回时间",
	lg60:"已赎回",
	lg61:"天",
	lg62:"小时",
	lg63:"分",
	lg64:"秒",
	lg65:"后才可赎回",
	lg66:"赎回中",
	lg67:"赎回成功",
	lg68:"赎回失败:",
	lg69:"RBOSS是由Rckununion去中心化达成共识价值体系生态。RBOSS通过LP分布式产出，100%全流通、全透明，用于联盟社区元宇宙的建设。",
	lg70:"登录中...",
	lg71:"质押地址",
	lg72:"去Sunswap质押",
	lg73:"质押占比",
	lg74:"选择质押时间",
	lg75:"年",
	lg76:"提取收益",
	lg77:"可提取金额",
	lg78:"手续费",
	lg79:"到账金额",
	lg80:"提取",
	lg81:"温馨提示：最低提现金额",
	lg82:"到账数量",
	lg83:"赎回质押",
	lg84:"赎回质押将扣除本金的50%作为手续费请确认是否赎回?",
	lg85:"检测上级中",
	lg86:"质押100 RCH起",
	lg87:"质押",
	lg88:"起",
	lg89:"最低质押：",
	lg90:"",
	lg91:"算力池子:",
	lg92:"质押时间:",
	lg93:"余额：",
	lg94:"赎回质押将扣除本金的12%作为手续费请确认是否赎回?",
	lg95:"请先注册/质押",
}