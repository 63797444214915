module.exports = {
	langType : 'en',
	lg1:"Withdrawable balance",
	lg2:"Extract the details",
	lg3:"extract",
	lg4:"My pledge",
	lg5:"The pledge to record",
	lg6:"Easy pledge",
	lg7:"The pledge",
	lg8:"It is expected to be excavated daily ",
	lg9:"The data is for reference only, please refer to the actual mining data prevail!",
	lg10:"The pledge amount",
	lg11:"Total amount of next mining",
	lg12:"Expect to find it next time",
	lg13:"My exclusive share link",
	lg14:"copy",
	lg15:"Cumulative invitation revenue",
	lg16:"Income details",
	lg17:"address",
	lg18:"Registration time",
	lg19:"In the load",
	lg20:"No more data is available",
	lg21:"Click to load more",
	lg22:"Cumulative extraction",
	lg23:"Residual extractable",
	lg24:"money",
	lg25:"type",
	lg26:"time",
	lg27:"Mine production",
	lg28:"extract",
	lg29:"from",
	lg30:"After the pledge is expected to dig out about daily ",
	lg31:"In the meantime, you need to pay ",
	lg32:"Confirm payment?",
	lg33:"cancel",
	lg34:"confirm",
	lg35:"A key to register",
	lg36:"Please enter the inviter's address",
	lg37:"Please enter the correct address",
	lg38:"The superior address cannot be its own",
	lg39:"In the register",
	lg40:"Registered successfully",
	lg41:"Registration failed:",
	lg42:"Please enter the correct amount of pledge",
	lg43:"Get balance",
	lg44:"Lack of balance",
	lg45:"In the pledge authority",
	lg46:"The pledge failure:",
	lg47:"The pledge of",
	lg48:"The pledge success",
	lg49:"A key to redeem",
	lg50:"You have no immediate withdrawable income",
	lg51:"In receipt of proceeds",
	lg52:"Extraction of success",
	lg53:"Extraction of failure:",
	lg54:"Copy success",
	lg55:"Copy the failure",
	lg56:"The pledge time",
	lg57:"The pledge number",
	lg58:"State of pledge",
	lg59:"To redeem the time",
	lg60:"retired",
	lg61:"day",
	lg62:"hours",
	lg63:"points",
	lg64:"seconds",
	lg65:"Only after redemption",
	lg66:"The redemption",
	lg67:"Successful redemption",
	lg68:"Redemption of failure:",
	lg69:"Rboss is a consensus value system ecology reached by the decentralization of rckununion. Rboss is distributed through LP, 100% fully circulated and transparent, and is used for the construction of the Alliance Community meta universe.",
	lg70:"Logging in...",
	lg71:"Pledge address",
	lg72:"To sunswap",
	lg73:"Proportion of pledge",
	lg74:"Select pledge time",
	lg75:"Year",
	lg76:"Withdrawal income",
	lg77:"Withdrawable amount",
	lg78:"Service Charge",
	lg79:"Amount received",
	lg80:"extract",
	lg81:"Warm tip: minimum withdrawal amount",
	lg82:"The account number",
	lg83:"Redeem the pledge",
	lg84:"Redemption pledge will deduct 50% of the principal as a commission fee, please confirm whether to redeem?",
	lg85:"Detection superior",
	lg86:"Pledge from 100 RCH",
	lg87:"pledge",
	lg88:"rise",
	lg89:"Minimum pledge:",
	lg90:"",
	lg91:"Pool:",
	lg92:"Time:",
	lg93:"Balance: ",
	lg94:"Redemption pledge will deduct 12% of the principal as a commission fee, please confirm whether to redeem?",
	lg95:"Please register/stake first",
}